import { Eventfetch } from "../api/eventApi";
import { createSlice } from '@reduxjs/toolkit'


  

  const eventSlice = createSlice({
    name: 'eventSlice',
    initialState : {
      event: [],
      loading: false,
    } ,
    reducers: {
      
    },
    extraReducers: (builder) => {
   
      builder.addCase(Eventfetch.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(Eventfetch.fulfilled , (state, action) => {
        state.event=action.payload;
        // console.log(state.event,"state.event")
        state.loading = false;
      })
      builder.addCase(Eventfetch.rejected, (state, action) => {
        state.loading = false;
      
      })
    },
  })
  export default eventSlice.reducer;
  