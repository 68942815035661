import { clientfetch } from '../api/clientApi';
import { createSlice } from '@reduxjs/toolkit'

  const clientSlice = createSlice({
    name: 'clientSlice',
    initialState : {
      client: [],
      loading: false,
    } ,
    reducers: {
      
    },
    extraReducers: (builder) => {
   
      builder.addCase(clientfetch.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(clientfetch.fulfilled , (state, action) => {
        state.client=action.payload;
      
        state.loading = false;
      })
      builder.addCase(clientfetch.rejected, (state, action) => {
        state.loading = false;
      
      })
    },
  })
  export default clientSlice.reducer;
  