import { Bannerfetch } from "../api/bannerAPI"
import { createSlice } from '@reduxjs/toolkit'

  const BannerSlice = createSlice({
    name: 'BannerSlice',
    initialState : {
        Banner: [],
      loading: false,
    } ,
    reducers: {
      
    },
    extraReducers: (builder) => {
   
      builder.addCase(Bannerfetch.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(Bannerfetch.fulfilled , (state, action) => {
        state.Banner=action.payload;
      
        state.loading = false;
      })
      builder.addCase(Bannerfetch.rejected, (state, action) => {
        state.loading = false;
      
      })
    },
  })
  export default BannerSlice.reducer;
  