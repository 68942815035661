import React from "react";


const Lodding = () => {
  return (
    <div className="loader-container">
    <div className="loader"></div>
  </div>
  );
};

export default Lodding;
