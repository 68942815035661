import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import { baseUrl } from './config';

export const pricingForm = createAsyncThunk(
    "post/pricingForm",
    async (data) => { 
        let url = baseUrl+'insertPrice';
        try{
             
            const response = (await axios.post(url,data)).data
        //   console.log(response,"response")
            if(response.status == "success"){
               
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const ContactUs = createAsyncThunk(
    "post/ContactUs",
    async (data) => { 
        let url = baseUrl+'insertContact';
        try{
             
            const response = (await axios.post(url,data)).data
          console.log(response,"response")
            if(response.status == "success"){
               
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const UserData = createAsyncThunk(
    "post/UserData",
    async (data) => { 
        let url = baseUrl+'bot';
        try{
             
            const response = (await axios.post(url,data)).data
          console.log(response,"response")
            if(response.status == "success"){
               
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)