import {testimonal} from "../api/testomonialsAPI";
import { createSlice } from '@reduxjs/toolkit'



  const testomonialSlice = createSlice({
    name: 'testomonialSlice',
    initialState : {
        testomonial: [],
      loading: false,
    } ,
    reducers: {
      
    },
    extraReducers: (builder) => {
   
      builder.addCase(testimonal.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(testimonal.fulfilled , (state, action) => {
        state.testomonial=action.payload;
        // console.log(state.testomonial,"state.testomonial")
        state.loading = false;
      })
      builder.addCase(testimonal.rejected, (state, action) => {
        state.loading = false;
      
      })
    },
  })
  export default testomonialSlice.reducer;
  