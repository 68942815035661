import { configureStore } from '@reduxjs/toolkit';
import eventReducer from "../slice/eventSlice";
import testimoinalReducer from "../slice/testomonialSlice"
import clientreducer from "../slice/clientSlice"
import BannerReducer from "../slice/bannerslice"
import PostReducer from "../slice/PostSlice"

export const store = configureStore({
  reducer: {
    eventSlice: eventReducer, 
    testomonialSlice:testimoinalReducer,
    clientSlice  :clientreducer,
    BannerSlice  :BannerReducer,
    PostSlice   :PostReducer,
  },
});
