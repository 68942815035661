import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Lodding from "./components/Lodder/Lodding";



const Template = React.lazy(() => import("./components/layout/Template"));
const Example = React.lazy(() => import("./screens/Example"));
const ContactHeader = React.lazy(() => import("./components/Contact/ContactHeader"));
const Aboutus = React.lazy(() => import("./components/AboutUs/Aboutus"));
const EventHeader = React.lazy(() => import("./components/Event/EventHeader"));
const EventHeader2 = React.lazy(() => import("./components/Event/EventHeader2"));
const Contact_us = React.lazy(() => import("./components/Contact/contact_us"));
const ThankYouPage = React.lazy(() => import("./components/Thankyou/thankyouPage"));
const PrivacyPolicy = React.lazy(() => import("./components/Privacy-policy/Privacy-policy"));


export default function RouteApp() {
 


  const ScrollToTop = () => {
    const { pathname } = useLocation();
   
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]); 
  
    return null;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Lodding />}>
     
          <Routes>
            <Route path="/" element={<Template><Example /></Template>} />
            <Route path="/ContactHeader" element={<Template><ContactHeader /></Template>} />
            <Route path="/Aboutus" element={<Template><Aboutus /></Template>} />
            <Route path="/event" element={<Template><EventHeader /></Template>} />
            <Route path="/EventHeader2" element={<Template><EventHeader2 /></Template>} />
            <Route path="/Contact_us" element={<Template><Contact_us /></Template>} />
            <Route path="/thankyou" element={<Template><ThankYouPage /></Template>} />
            <Route path="/privacy-policy-2" element={<Template><PrivacyPolicy /></Template>} />
            <Route path="/loddng" element={<Lodding />} />
          </Routes>
     
      </Suspense>
    </BrowserRouter>
  );
}
