import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import { baseUrl } from './config';

export const Eventfetch = createAsyncThunk(
    "get/Eventfetch",
    async () => { 
        let url = baseUrl+'fetchEvents';
        try{
             
            const response = (await axios.get(url)).data
        //   console.log(response,"response")
            if(response.status == "success"){
               
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)