import React from 'react';
import ReactDOM from 'react-dom/client'; // Use createRoot from ReactDOM
import App from './App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import { Provider } from 'react-redux';
import { store } from './apis/store/store';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
  <Provider store={store}>
    <App  />
    </Provider>
  </>
);


reportWebVitals();
