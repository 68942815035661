import {pricingForm,ContactUs,UserData} from "../api/PostApi";
import { createSlice } from '@reduxjs/toolkit'



  const PostSlice = createSlice({
    name: 'PostSlice',
    initialState : {
       price: [],
       contact:[],
      loading: false,
    } ,
    reducers: {
      
    },
    extraReducers: (builder) => {
   
      builder.addCase(pricingForm.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(pricingForm.fulfilled , (state, action) => {
        state.price=action.payload;
        console.log(state.price,"state.price")
        state.loading = false;
      })
      builder.addCase(pricingForm.rejected, (state, action) => {
        state.loading = false;
      
      })

         //  Contact us Api slice 

      builder.addCase(ContactUs.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(ContactUs.fulfilled , (state, action) => {
        state.contact=action.payload;
       
        state.loading = false;
      })
      builder.addCase(ContactUs.rejected, (state, action) => {
        state.loading = false;
      
      })

      builder.addCase(UserData.pending, (state, action) => {
        state.loading = true;

      })
      builder.addCase(UserData.fulfilled , (state, action) => {
        state.contact=action.payload;
       
        state.loading = false;
      })
      builder.addCase(UserData.rejected, (state, action) => {
        state.loading = false;
      
      })

    },
  })
  export default PostSlice.reducer;
  